var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container pt-8"},[(_vm.isCoto)?_c('div',{staticClass:"btn-returnApp mx-auto"},[_c('v-btn',{staticClass:"btnPrimary text-none",attrs:{"text":"","ripple":false,"depressed":"","exact":"","to":{
        name: 'operator-dashboard'
      }}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Torna all'elenco appuntamenti ")],1)],1):_vm._e(),_c('v-card',{staticClass:"px-8 py-8 mx-auto card-detailApp",attrs:{"max-width":"684px"}},[_c('ErrorBox',{attrs:{"error":_vm.detailError}}),_c('v-card-title',[_vm._v(" Dettaglio appuntamento ")]),_c('v-list',{staticClass:"dettaglio-appuntamento mb-8"},[_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Utente ")]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.appointment.user.name)+" "+_vm._s(_vm.appointment.user.surname))])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Contatti utente ")]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.phone))]),_vm._v(" (telefono) - "),_c('strong',[_vm._v(_vm._s(_vm.email))]),_vm._v(" (e-mail)")])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" URL stanza utente ")]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.videJoinUrl))])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Inizio ")]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.formatDate(_vm.appointment.begin_date)))])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Fine ")]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.formatDate(_vm.appointment.end_date)))])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Sportello ")]),_c('v-col',[(
                _vm.appointment.front_office_name != null &&
                  _vm.appointment.front_office_name != ''
              )?_c('strong',[_vm._v(_vm._s(_vm.appointment.front_office_name))]):_c('strong',[_vm._v("N.D.")])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Note ")]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.notes))])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Doc. inviati ")]),_c('v-col',[(_vm.sentFiles.length == 0)?_c('div',[_c('strong',[_vm._v("Nessun file spedito")])]):_c('div',[_c('ul',_vm._l((_vm.sentFiles),function(item,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadFile(item.attachment_id, item.filename)}}},[_c('strong',[_vm._v(_vm._s(item.filename))])])])}),0)])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Doc. ricevuti ")]),_c('v-col',[(_vm.receivedFiles.length == 0)?_c('div',[_c('strong',[_vm._v("Nessun file ricevuto")])]):_c('div',[_c('ul',_vm._l((_vm.receivedFiles),function(item,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadFile(item.attachment_id, item.filename)}}},[_c('strong',[_vm._v(_vm._s(item.filename))])])])}),0)])])],1)],1)],1),_c('v-card-actions',[(_vm.validAppointment)?_c('v-btn',{staticClass:"btnPrimary btn--Large",attrs:{"to":{
          name: 'operator-appointment',
          params: { slug: _vm.appointment.appointment_id }
        },"target":"_blank"}},[_vm._v("Inizia la chiamata")]):_vm._e(),(_vm.openAppointment)?_c('v-btn',{staticClass:"btnPrimary btn--Large",attrs:{"to":{
          name: 'operator-appointment',
          params: { slug: _vm.appointment.appointment_id }
        },"target":"_blank"}},[_vm._v("Riprendi la chiamata")]):_vm._e(),(_vm.closedAppointment)?_c('div',{staticClass:"fontSemibold"},[_vm._v(" La chiamata è terminata ")]):_vm._e(),(_vm.deletedAppointment)?_c('div',{staticClass:"fontSemibold"},[_vm._v(" La chiamata è annullata ")]):_vm._e()],1)],1),_c('Spinner',{attrs:{"pOverlay":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }